<!--
 * @Author: 智客云网络科技
 * @Date: 2021-04-12 17:07:57
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-03-27 23:27:55
 * @Description: 请输入文件描述信息
 * @FilePath: \src\views\Frame\InstrumentPanel\InstrumentPanel.vue
-->

<template>
  <div class="vue-box">
    <!-- 欢迎 -->

    <img
      src="@/assets/img/5cf0d15003d19.png"
      alt=""
      :style="{ width: '100%' }"
    />

    <!-- <el-row :gutter="20">
      <el-col :span="24" style="padding-bottom: 20px">
        <div
          style="
            padding: 1em;
            border-left: 5px #2d8cf0 solid;
            background-color: #eee;
          "
        >
          <big><big>欢迎登陆 飞鱼国际物流-TMS管理系统</big></big>
          <span style="display: inline-block; margin-left: 1em; color: #888">
            —— 专业 易用 简单 智能</span
          >
        </div>
      </el-col>
    </el-row> -->

    <!-- <el-row :gutter="20">
      <el-col :span="24" style="padding-bottom: 20px">
        <el-card shadow="hover">
          <el-row type="flex" justify="start" align="middle">
            <el-col :span="2" style="text-align: center">
              <el-avatar :size="110" class="user-avatar" :src="avatar">
                <img src="@/assets/avatar/avatar-load-fail.png" />
              </el-avatar>
            </el-col>
            <el-col :span="22">
              <div class="info-box">
                <div class="info-wh">
                  {{ $state.greet }}，{{ $state.user.nickname }}，{{
                    $state.bless
                  }}
                </div>
              </div>
              <div class="sign-box">
                <div class="info-sign">{{ $state.user.sign }}</div>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row> -->
  </div>
</template>

<script>
export default {
  name: "InstrumentPanel",
  data() {
    return {
      avatar:
        process.env.VUE_APP_BASE_URL +
        "/resources/" +
        this.$store.state.user.avatar, //头像
      img: "@/assets/image/5cf0d15003d19.png",
    };
  },
  methods: {},
  mounted() {},
};
</script>
